<template>
  <div />
</template>

<script>


export default {
  components: {
  },

  data() {
    return {
      config:{}
    };
  },
  created() {
    window.addEventListener( 'message', this.receiveMessageFromParent );
    console.log("widget created")
  },
  beforeDestroy(){
    window.removeEventListener("message",this.receiveMessageFromParent);
    console.log("widget destroy")
  },
  mounted(){
    // const message={cmd:'mounted',name:this.$route.query.name}
    this.sendMessage('mounted',this.$route.query.name,"");
  },
  methods:{
    receiveMessageFromParent(e){
      console.log('receive widget',e.data)
      if (e.data == null) return;
      if (e.data.cmd == 'config' && e.data.name==this.$route.query.name) {
        console.log('mymessage',e.data);
        this.config = e.data.data;
        this.receiveConfig(this.config);
      }else if(e.data.cmd=='syncData'){
        this.receiveSyncData(e.data.data);
      }
    },
    sendMessage(cmd,name,data){
      const message = {cmd,name,data}
      window.parent.postMessage( message, '*' );
    },

    // 최초 실행 후 와 Interval 마다 받는 Config
    receiveConfig(config){
      console.log("widget receiveConfig");
    },

    // sendSyncData로 부터 받는 곳
    receiveSyncData(data){
      console.log("widget receive change data");
    },
    // receiveSyncData에 보내는 곳
    sendSyncData(data){
      this.sendMessage('syncData',this.$route.query.name,data);
    },
    // Link 보낼때 widget에서 보낸 data를 함께 보내기 위해 widget에서 보내는 data
    sendWidgetData(data){
      this.sendMessage('widgetData',this.$route.query.name,data);

    },

    // 선택한 Link와 widget Data를 요청
    sendLinkWidgetData(data){
      this.sendMessage('linkWidgetData',this.$route.query.name,data);

    },
    sendLinkRequest(data){
      this.sendMessage('linkRequest',this.$route.query.name,data);

    }
  }
};
</script>
<style>

</style>
